import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { animated, useSpring, useTrail, config } from 'react-spring'
import Main from '../components/layout/main'
// import HoverBox from '../components/elements/HoverBox'

export const query = graphql`
  query Index {
    firstProject: projectsYaml {
      title
      slug
      cover {
        childImageSharp {
          fluid(quality: 95, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    threeProjects: allProjectsYaml(limit: 3, skip: 1) {
      nodes {
        title
        slug
        cover {
          childImageSharp {
            fluid(quality: 95, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    aboutUs: file(sourceInstanceName: { eq: "images" }, name: { eq: "about-us" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Index = ({ data: { firstProject, threeProjects, aboutUs } }) => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  const title = 'asd'
  const description = 'asd'
  const date = 'asd'
  const link = 'asds'
  const gatsbyImage = firstProject.cover
  const style = {}

  return (
    <Main>
      <GridStyle style={pageAnimation}>
        {/* <HoverBox
          gatsbyImage={gatsbyImage}
          link={link}
          title={title}
          description={description}
          date={date}
          style={{
            gridArea: 'primary-box'
          }}
        />
        <HoverBox
          gatsbyImage={gatsbyImage}
          link={link}
          title={title}
          description={description}
          date={date}
          style={{
            gridArea: 'secondary-box'
          }}
          portrait
        /> */}

        {/* <PrimaryPieceStyle to={firstProject.slug} aria-label={`View project "${firstProject.title}"`}>
          <Img fluid={firstProject.cover.childImageSharp.fluid} />
          <span>{firstProject.title}</span>
        </PrimaryPieceStyle>

        <SecondaryPieceStyle to="/about" aria-label="Visit my about page">
          <Img fluid={aboutUs.childImageSharp.fluid} />
          <span>About</span>
        </SecondaryPieceStyle>
        {threeProjects.nodes.map(project => (
          <GridItem to={project.slug} key={project.slug} aria-label={`View project "${project.title}"`}>
            <Img fluid={project.cover.childImageSharp.fluid} />
            <span>{project.title}</span>
          </GridItem>
        ))} */}
      </GridStyle>
    </Main>
  )
}


const GridStyle = styled(animated.div)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas:
    'primary-box primary-box secondary-box'
    'primary-box primary-box secondary-box'
`

// const GridStyle = styled(animated.div)`
//   display: grid;
//   grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
//   @media (max-width: ${props => props.theme.breakpoints[4]}) {
//     grid-template-columns: 1fr 1fr 1fr;
//   }
//   @media (max-width: ${props => props.theme.breakpoints[3]}) {
//     grid-template-columns: 1fr 1fr;
//   }
//   @media (max-width: ${props => props.theme.breakpoints[0]}) {
//     grid-template-columns: 1fr;
//   }
// `

export default Index
